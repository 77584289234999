<template>
  <div class="content">
    <div class="contentbox">
      <el-tabs   v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="进行中" name="0"></el-tab-pane>
        <el-tab-pane label="验收完成" name="1"></el-tab-pane>
      </el-tabs>
      <div class="sonContent" >
        <div class="SearchBar">
          <div style="display: flex">
            <span style="margin: 6px 10px 0 20px ">工程编号/名称:</span>
            <el-input style="width: 200px" placeholder="请输入" size="small"  v-model="searchBox.numberOrName"></el-input>
            <span style="margin: 6px 10px 0 20px ">现场管理员:</span>
            <el-input style="width: 200px" placeholder="请输入" size="small"  v-model="searchBox.siteManager"></el-input>
            <span style="margin: 6px 10px 0 20px ">工程类型:</span>
            <el-select v-model="searchBox.projectType" style="width:200px;" placeholder="请选择" size="small" clearable>
              <el-option
                  v-for="item in tuiliaoList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <span style="margin: 6px 10px 0 20px ">第三方施工队：</span>
            <el-input style="width: 200px" placeholder="请输入" size="small"  v-model="searchBox.teamName"></el-input>
            <el-button type="primary" class="SearchBtn" size="small" @click="search" style="margin-left: 10px">查询</el-button>
          </div>

        </div>

        <!--数据表格-->
        <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 15px 0;"
            border
            height="calc(100vh - 394px)"
            :stripe="true">
          <el-table-column type="index" label="序号" width="70" align="center"> </el-table-column>
          <el-table-column prop="projectNumber" label="工程编号" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="projectName" label="工程名称" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="projectType" label="工程类型" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="teamName" label="第三方施工队" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="projectSite" label="工程地点" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="siteManager" label="现场管理员" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="lastSubmitTime" label="提交时间" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" align="left" show-overflow-tooltip>
            <template slot-scope="{row}">
              <el-button  @click="showDetil(row)" type="primary" size="small">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
              :current-page="searchBox.current"
              :background="true"
              :page-sizes="[ 30, 50, 100]"
              :page-size="searchBox.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        <el-dialog
            title="审核详情"
            :visible.sync="detildialogVisible"
            width="60%">
          <div >
            <p class="detil-code">
              工程信息
            </p>
            <div style="padding:0 10px">
              <div style="display: flex;line-height: 50px">
                <div style="flex: 1;">工程编号：{{project.projectNumber}}</div>
                <div style="flex: 1;">工程名称：{{project.projectName}}</div>
                <div style="flex: 1;">工程类型：{{project.projectType}}</div>
              </div>
              <div style="display: flex;line-height: 50px">
                <div style="flex: 1;">现场管理员：{{project.siteManager}}</div>
                <div style="flex: 1;">第三方施工队：{{project.teamName}}</div>
                <div style="flex: 1;">工程地点：{{project.projectSite}}</div>
              </div>
            </div>
            <div style="display: flex;padding-top:20px;">
              <div style="flex: 1;">
                <p class="detil-code2" style="margin-bottom: 20px">
                  第三方施工队工程量汇总（截至当前）<el-button size="small" type="primary" @click="exportFile">导出</el-button>
                </p>
                <el-table
                    ref="tableData"
                    :data="project.projectProgress"
                    style="width: 100%;margin-top: 10px"
                    border
                    :stripe="true"
                    :header-cell-style="{'background-color': '#e7e7e7'}">
                  <el-table-column type="index" label="序号" width="70" align="center"> </el-table-column>
                  <el-table-column prop="materialName" label="物料名称" align="center" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="amount" label="数量" align="center" show-overflow-tooltip width="100"></el-table-column>
                  <el-table-column prop="unit" label="单位" align="center" show-overflow-tooltip width="100"></el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
    <el-button @click="detildialogVisible = false">关 闭</el-button>
  </span>
        </el-dialog>
      </div>
    </div>

  </div>
</template>

<script>
import {
  doingProjectList,
  doneProjectList,
  materialSummary,
  materialProgressExport, materialSummaryExport,
} from '../../RequestPort/cost/cost'
import { getDict } from "../../apis/commonType";
import { exportMethod } from '../../common/js/exportExcel'
export default {
  name: "dataMember",
  data(){
    return{
      activeName:0,
      detildialogVisible:false,
      searchBox:{
        current:1,
        size: 100,
        numberOrName:'',
        projectType:'',
        teamName:''
      },
      total:null,
      tableData:[],
      project:{},
      tuiliaoList:[ ],
      teamId:'',
      workId:'',
    }
  },
  mounted(){
    getDict({parentCode:'2100'}).then(res=>{
      this.tuiliaoList = res.data
    })
    this.loadList(this.searchBox)
  },
  methods:{
    exportFile(){
      let obj = {
        teamId:this.teamId,
        workId:this.workId
      }
      materialProgressExport(obj).then(res=>{
        exportMethod(res, this.project.projectName+this.project.teamName+'-工程用料')
      })
    },
    exportFiletwo(){
      let obj = {
        teamId:this.teamId,
        workId:this.workId
      }
      materialSummaryExport(obj).then(res=>{
        exportMethod(res, this.project.projectName+this.project.teamName+'-工程用料')
      })
    },
    handleClick(tab){
      this.activeName = tab.index
      this.searchBox={
        current:1,
        size: 100,
        numberOrName:'',
        projectType:'',
        teamName:''
      }
      if(tab.index==0){
        this.loadList(this.searchBox)
      }else {
        this.loaddoneList(this.searchBox)
      }
    },
    showDetil(row){
      this.detildialogVisible = true
      this.teamId = row.teamId
      this.workId = row.id
      materialSummary({teamId:row.teamId,workId:row.id}).then(res=>{
        this.project = res.data
      })
    },
    loadList(obj){
      doingProjectList(obj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    loaddoneList(obj){
      doneProjectList(obj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val){
      this.searchBox.current = 1
      this.searchBox.size = val
      if(this.activeName==0){
        this.loadList(this.searchBox)

      }else {
        this.loaddoneList(this.searchBox)

      }
    },
    handleCurrentChange(val){
      this.searchBox.current = val
      if(this.activeName==0){
        this.loadList(this.searchBox)

      }else {
        this.loaddoneList(this.searchBox)

      }
    },
    search(){
      this.searchBox.current = 1
      if(this.activeName==0){
        this.loadList(this.searchBox)

      }else {
        this.loaddoneList(this.searchBox)

      }
    },
  }
}
</script>

<style scoped>
.content{
  width: 100%;
  background: #f3f4f8;
}
.contentbox {
  margin: 10px;
  padding: 10px;
  background-color:#fff;
  position: relative;
}
.SearchBar {
  border-radius: 5px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.detil-code{
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before{
  content: '';
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007AFF;
  top: 0!important;
  left: 0;
}
.detil-code2{
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code2:before{
  content: '';
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007AFF;
  top: 7px!important;
  left: 0;
}
</style>